import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/B8dq8XxNWrU">
    <SEO title="FOR - Romans: Practice" />
  </Layout>
)

export default SermonPost
